<template>
  <List />
</template>

<script>
export default {
  name: "systemUsers",

  components: {
    List:()=> import("@/components/manage/purpose/List.vue"),
  },
};
</script>
